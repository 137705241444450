<template>
  <div class="courseList">
    <div class="courseList-t">
      <div class="courseList-tl">
        <v-dropDownMenu
          v-model="requestData.gradeType"
          :option1="stageSts"
          @change="change"
        ></v-dropDownMenu>
      </div>
      <div class="courseList-tr">
        <div
          :class="['course', { active: requestData.courseType == item.key }]"
          v-for="(item, index) in subjectList"
          :key="index"
          @click="selectCourse(item)"
        >
          {{ item.txt }}
        </div>
      </div>
    </div>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :immediateCheck="false"
      ref="list"
      :finishedText="finishedText"
    >
      <div class="main">
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="item-t">
            <img :src="item.imageUrl" alt="" />
          </div>
          <div class="item-b">
            <div class="title">
              {{ item.chapterTitle }}
              {{ item.courseTitle }}
            </div>
            <div class="info">执教:{{ item.teacher }}</div>
            <div class="info">学校:{{ item.school }}</div>
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { courseListUrl } from "./api.js";
import { stageSts } from "./map.js";
export default {
  name: "courseList",
  data() {
    return {
      stageSts,
      isLoading: false,
      finished: false,
      finishedText: "没有更多了",
      listData: [],
      curPage: 1,
      pageSize: 10,
      requestData: {
        gradeType: "3",
        courseType: 1,
        title: "",
      },
      subjectList: [
        { url: require("./img/yuwen.png"), txt: "语文", key: "1" },
        { url: require("./img/shuxue.png"), txt: "数学", key: "2" },
        { url: require("./img/english.png"), txt: "英文", key: "3" },
      ],
    };
  },
  computed: {
    ...mapState(["userId"]),
  },
  created() {
    this.requestData.gradeType = this.$route.query.gradeType;
    this.requestData.title = this.$route.query.title;
    this.requestData.courseType = this.$route.query.courseType;
    this.getCourseList();
  },
  activated() {
    // if (!this.$route.meta.isUseCache) {
    //   console.log(this.$route.query.gradeType, "+++++");
    //   this.curPage = 1;
    //   this.listData = []; // 清空原有数据
    //   this.onLoad(); // 这是我们获取数据的函数
    // } else {
    //   this.$route.meta.isUseCache = false;
    // }
  },
  deactivated() {
    // this.requestData.gradeType = "";
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "courseDetail",
        query: {
          courseId: item.id,
          gradeType: this.requestData.gradeType,
          courseType: this.requestData.courseType,
          title: this.requestData.title,
        },
      });
    },
    selectCourse(item) {
      this.curPage = 1;
      this.finished = false;
      this.$refs.list._data.loading = true;
      this.requestData.courseType = item.key;
      this.listData = [];
      this.getCourseList();
    },
    change(value) {
      this.curPage = 1;
      this.finished = false;
      this.$refs.list._data.loading = true;
      this.requestData.gradeType = value;
      this.listData = [];
      this.getCourseList();
    },

    getCourseList() {
      let params = {
        userId: this.userId,
        curPage: this.curPage++,
        pageSize: this.pageSize,
        courseType: this.requestData.courseType,
        gradeType: this.requestData.gradeType,
        title: this.requestData.title,
      };
      this.$axios.get(`${courseListUrl}`, { params: params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.activityPicture) {
            }
            this.listData.push(item);
          });
        }
      });
    },
    // 加载列表数据
    onLoad() {
      this.getCourseList();
    },
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    toRoute(item) {
      this.$router.push({
        name: "activityUserList",
        query: {
          activityId: item.activityId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.courseList {
  box-sizing: border-box;
  min-height: 100vh;

  .courseList-t {
    display: flex;
    padding: 0 30px;
    .courseList-tr {
      display: flex;
      align-items: center;
      margin-left: 40px;
      .course {
        width: 150px;
        height: 54px;
        background: #f7f7f7;
        border-radius: 12px;
        border: 2px solid #cbcccd;
        margin: 0 40px 0 0;
        font-size: 28px;
        font-weight: 400;
        color: #646668;
        line-height: 54px;
        text-align: center;
      }
      .active {
        width: 150px;
        height: 54px;
        background: #cce5ff;
        border-radius: 12px;
        border: 2px solid #007eff;
        color: #0682ff;
      }
    }
  }
  .main {
    display: flex;
    padding: 0 26px;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      flex-shrink: 0;
      width: 340px;
      height: 390px;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 12px;
      margin-bottom: 20px;
      .item-t {
        width: 100%;
        height: 218px;
        img {
          width: 100%;
          border-radius: 12px 12px 0 0;
          height: 100%;
        }
      }
      .item-b {
        padding: 16px 0 0 16px;
        .title {
          font-size: 24px;
          font-weight: 400;
          color: #262626;
          line-height: 30px;
          text-shadow: 0px 0px 32px rgba(66, 114, 153, 0.1);
          margin-bottom: 8px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .info {
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
          text-shadow: 0px 0px 32px rgba(66, 114, 153, 0.1);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
<style lang="less">
.courseList {
  .van-dropdown-menu__bar {
    box-shadow: none;
    .van-dropdown-menu__title {
      font-size: 32px;
      font-weight: 700;
      color: #323334;
    }
  }
  .van-search {
    padding: 0;
  }
  .van-search .van-cell {
    align-items: center;
  }
  .van-search__content {
    width: 494px;
    height: 72px;
    background: #f5f5f5;
    border-radius: 40px;
  }
  .van-icon-search::before {
    content: "";
    width: 32px;
    background: url("./img/search.png") no-repeat;
    height: 32px;
    background-size: 100% 100%;
  }
  .van-dropdown-menu__title::after {
    border-color: transparent transparent #323334 #323334;
  }
  .van-dropdown-menu__title--down::after {
    border-color: transparent transparent #007eff #007eff;
  }
}
</style>
